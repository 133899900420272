<template>
  <div class='exit' v-if='isLoggedIn'>
    <v-btn icon @click='logout()' >
      <v-icon>exit_to_app</v-icon>
    </v-btn>
  </div>
</template>
<script>
import { onLogout } from '../apollo'
import { mapActions } from 'vuex'

export default {
  methods: {
    logout () {
      const client = this.$apollo.getClient()
      onLogout(client)
      this.setAuthUser(null)
      this.$router.push('/')
    },
    ...mapActions(['setAuthUser'])
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    }
  }
}
</script>

<style lang='scss'>
.exit {
  position: absolute;
  text-align: right;
  width: 100%;
  z-index: 999;
  padding: 20px 40px;
}
</style>
